import withRoot from '../../utils/withRoot';
import React from 'react';
import { Link, graphql, withPrefix } from 'gatsby';
import Typography from '@material-ui/core/Typography';
import SEO from '../../components/SEO';
import Page from '../../components/Page';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import { Wrench } from 'mdi-material-ui';

const Team = (props) => {
	const teams = props.data.allMarkdownRemark.edges;
	return (
		<Page title="Par mums">
			<SEO title="Par mums" />
			<Typography paragraph>
				Mēs esam savas lietas meistari. Darbā tiek pieņemti tikai montāžnieki ar nopietnu pieredzi vārtu
				uzstādīšanā. Savukārt, visiem mūsu menedžeriem ir vairāku gadu pieredze. Tie nav vienkārši skaitļi - tā
				ir mūsu darba kvalitātes garantija.
			</Typography>

			<GridList cellHeight={500} cols={2}>
				{teams.map((edge) => {
					const { node: { frontmatter: { path, title, image: { publicURL }, jobtitle } } } = edge;
					return (
						<Link key={path} to={path}>
							<GridListTile cols={1}>
								<img src={withPrefix(publicURL)} alt={title} />
								<GridListTileBar
									title={title}
									subtitle={jobtitle}
									actionIcon={
										<IconButton className="teamIcon" style={{ color: 'rgba(255, 255, 255, 0.54)' }}>
											<Wrench />
										</IconButton>
									}
								/>
							</GridListTile>
						</Link>
					);
				})}
			</GridList>
		</Page>
	);
};

export const query = graphql`
	query TeamQuery {
		allMarkdownRemark(
			filter: { fileAbsolutePath: { regex: "/team/" } }
			sort: { fields: [frontmatter___date], order: DESC }
		) {
			edges {
				node {
					html
					frontmatter {
						image {
							publicURL
						}
						title
						path
						jobtitle
					}
				}
			}
		}
	}
`;

export default withRoot(Team);
